import React, { useState, useRef } from 'react'
import './index.css'
import '../SharedCss/alert.css'
import '../SharedCss/form.css'
import CredentialsForm from './CredentialsForm'
import MfaForm from './MfaForm'
import NewPasswordForm from './NewPasswordForm'
import { Link } from 'react-router-dom'
import CustomLogin from '../CustomLogin'
import { signInExternalHost, signInExternalHostWithCustomRedirect } from '../ExternalHosts'
import { signIn } from '../Auth'
import { getAdvisorCustomLogin } from '../AdvisorCustomLogin'
import Flash from '../Flash'
import Logger from '../Logger'
import LoadingBar from 'react-top-loading-bar'
import ALERT_TYPES from '../SharedConstants/AlertTypes'

require('dotenv').config()

export default function LoginForm (props) {
  const advisorId = getAdvisorCustomLogin()

  const { CognitoIdentityLib } = props
  let initialAlertType = ''
  const flashMessage = Flash.getMessage()
  if (flashMessage !== null) {
    initialAlertType = ALERT_TYPES.FLASH
  }

  const [confirmationEmail, setConfirmationEmail] = useState('')
  const [alertType, setAlertType] = useState(initialAlertType)
  const [showMfaForm, setShowMfaForm] = useState(false)
  const [showNewPasswordForm, setShowNewPasswordForm] = useState(false)
  const [sessionUserAttributes, setSessionUserAttributes] = useState(null)
  const [destination, setDestination] = useState('')
  const [session, setSession] = useState('')
  const loadingRef = useRef(null)

  const params = new URLSearchParams(window.location.search)
  const returnToUrl = params.get('return_to')

  const userPool = new CognitoIdentityLib.CognitoUserPool({
    UserPoolId: process.env.USER_POOL_ID,
    ClientId: process.env.COGNITO_APP_ID
  })

  const authenticate = (email, password) => {
    Logger.start('Authenticate', { email: email })
    setAlertType('')
    loadingRef.current.continuousStart()
    // From this documentation
    // https://docs.aws.amazon.com/cognito/latest/developerguide/authentication.html

    const authenticationData = {
      Username: email,
      Password: password
    }

    const authenticationDetails = new CognitoIdentityLib.AuthenticationDetails(authenticationData)
    const userData = {
      Username: email,
      Pool: userPool
    }
    const cognitoUser = new CognitoIdentityLib.CognitoUser(userData)

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (cognitoTokens) => {
        Logger.done('Authenticate', { email: email })
        signIn(cognitoTokens)
        if (returnToUrl) {
          signInExternalHostWithCustomRedirect(returnToUrl)
        } else {
          signInExternalHost().then()
        }
      },
      onFailure: (err) => {
        loadingRef.current.complete()
        Logger.error('Authenticate', err)
        if (err.name === 'NotAuthorizedException') {
          setAlertType(ALERT_TYPES.INVALID_CREDENTIALS)
        } else if (err.name === 'UserNotConfirmedException') {
          // email will change so we need to store this in another state
          setConfirmationEmail(email)
          setAlertType(ALERT_TYPES.USER_NOT_CONFIRMED)
        }
      },
      mfaRequired: function (codeDeliveryDetails, params) {
        Logger.info('Authenticate, Mfa required', { email: email })
        setSession(cognitoUser)
        setDestination(params.CODE_DELIVERY_DESTINATION)
        setShowMfaForm(true)
      },
      newPasswordRequired: function (userAttributes, requiredAttributes) {
        Logger.info('Authenticate, New Password required', { email: email })
        userAttributes.nickname = userAttributes.email
        setSession(cognitoUser)
        setSessionUserAttributes(userAttributes)
        setShowNewPasswordForm(true)
      }
    })

    return false
  }

  const renderAlert = () => {
    if (alertType === ALERT_TYPES.INVALID_CREDENTIALS) {
      return (
        <div styleName="alert error">
          The email address / password combination you entered was not found. If the problem persists you may reset your password
          &nbsp;
          <Link styleName="forgot-password-link" to="/forgot_password" style={{ color: 'black', textDecoration: 'underline' }}>here</Link>.
        </div>
      )
    } else if (alertType === ALERT_TYPES.FLASH && flashMessage !== null) {
      const markup = { __html: flashMessage }
      return <div styleName="alert success" dangerouslySetInnerHTML={markup} />
    } else if (alertType === ALERT_TYPES.LOGIN_SUCCESSFULLY) {
      return (
        <div styleName="alert success">
          Login successfully. Redirecting to main application....
        </div>
      )
    } else if (alertType === ALERT_TYPES.RESENT_CONFIRMATION_LIMIT_EXCEEDED) {
      return (
        <div styleName="alert error">
          Attempt limit exceeded, please try after some time.
        </div>
      )
    } else if (alertType === ALERT_TYPES.RESENT_CONFIRMATION) {
      return (
        <div styleName="alert success">
          Email sent!! Please check your email to confirm.
        </div>
      )
    } else if (alertType === ALERT_TYPES.USER_NOT_CONFIRMED) {
      return (
        <div styleName="alert error">
          Please confirm your email address to sign into Blueleaf.
          &nbsp;
          <a id="resend-confirmation-btn" onClick={handleResendConfirmation} styleName="resend-confirmation-link">Resend confirmation</a>
        </div>
      )
    }
  }

  // *********
  // CALLBACKS
  // *********

  const handleResendConfirmation = (e) => {
    e.preventDefault()

    const userData = {
      Username: confirmationEmail,
      Pool: userPool
    }
    const cognitoUser = new CognitoIdentityLib.CognitoUser(userData)
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        setAlertType(ALERT_TYPES.RESENT_CONFIRMATION_LIMIT_EXCEEDED)
      } else {
        setAlertType(ALERT_TYPES.RESENT_CONFIRMATION)
      }
    })
  }

  // *********
  // RENDER
  // *********

  if (showMfaForm) {
    return <MfaForm
      cognitoUser={ session }
      destination={ destination } />
  } else if (showNewPasswordForm) {
    return <NewPasswordForm
      cognitoUser={ session }
      sessionUserAttributes= { sessionUserAttributes } />
  } else if (advisorId) {
    return <div>
      { renderAlert() }

      <LoadingBar color='#f11946' ref={loadingRef} />
      <CustomLogin
        advisorId={ advisorId }
        authenticate={ authenticate }/>
    </div>
  } else {
    return <div styleName="container">
      { renderAlert() }

      <LoadingBar color='#f11946' ref={loadingRef} />
      <CredentialsForm
        authenticate={ authenticate }/>
    </div>
  }
}
